import React, { useState } from "react";
import { FAQSection, FAQTitle, FAQItem, FAQAnswer, FAQQuestion } from "./styled";
import useContent from '../../developers/useContent';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const content = useContent();
  const { title, items: faqs } = content.faq;

  const toggleFAQ = (index, event) => {
    if (event) {
      event.preventDefault();
    }
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQSection>
      <FAQTitle>{title}</FAQTitle>
      {faqs.map((faq, index) => (
        <FAQItem key={index}>
          <FAQQuestion onClick={(e) => toggleFAQ(index, e)}>
            {faq.question}
          </FAQQuestion>
          <FAQAnswer isActive={activeIndex === index}>
            {faq.answer}
          </FAQAnswer>
        </FAQItem>
      ))}
    </FAQSection>
  );
};

export default FAQ; 